import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/right-arrow-black.svg'
import _imports_1 from '@/assets/images/google-logo.png'


const _hoisted_1 = { class: "body_wrap login_page" }
const _hoisted_2 = { class: "innerpage_formCard" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "cmn_cardInner space" }
const _hoisted_5 = { class: "row justify-content-center" }
const _hoisted_6 = { class: "col-lg-6" }
const _hoisted_7 = { class: "form_area text-center mb-5" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "formGroup_inner" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "formGroup_inner" }
const _hoisted_12 = { class: "stay-connected d-flex justify-content-between" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "custom-control custom-checkbox mb-3" }
const _hoisted_15 = { class: "form-text" }
const _hoisted_16 = { class: "text-success text-center" }
const _hoisted_17 = { class: "text-danger text-center" }
const _hoisted_18 = { class: "anotherLogin text-center" }
const _hoisted_19 = { class: "login_btns" }
const _hoisted_20 = { class: "form-text text-muted dont_have_account" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_section),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "sairaR mb-5" }, "Login", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "answerInputEmail1" }, "Email Address", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fa fa-envelope-o" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                      placeholder: "Email"
                    }, null, 512), [
                      [_vModelText, _ctx.email]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { for: "answerInputPassword1" }, "Password", -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fa fa-key" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "password",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                      placeholder: "Password"
                    }, null, 512), [
                      [_vModelText, _ctx.password]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        class: "custom-control-input",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stayConnected) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.stayConnected]
                      ]),
                      _cache[10] || (_cache[10] = _createElementVNode("label", {
                        class: "custom-control-label pl-2",
                        for: "customCheck"
                      }, "Stay Connected", -1))
                    ])
                  ]),
                  _createElementVNode("small", _hoisted_15, [
                    _createVNode(_component_router_link, {
                      to: { name: 'ForgotPassword' },
                      class: "cm-color"
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Forgot Password?")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.success), 1),
                _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.error), 1),
                _createVNode(_component_a_button, {
                  class: "cm_bg cm-btn text-white mt-4",
                  type: "primary",
                  loading: _ctx.isLoading,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSubmit())),
                  onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.onSubmit()), ["enter"]))
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Submit "),
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "",
                      class: "ml-1"
                    }, null, -1)
                  ])),
                  _: 1
                }, 8, ["loading"])
              ]),
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "differ_option" }, [
                _createElementVNode("span", null, "OR")
              ], -1)),
              _createElementVNode("div", _hoisted_18, [
                _cache[17] || (_cache[17] = _createElementVNode("h3", { class: "sairaR mb-4" }, "Other Login Options", -1)),
                _createElementVNode("ul", _hoisted_19, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_a_button, {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onGoogleSignIn())),
                      class: "cm-btn google_btn"
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: "Google Logo image"
                        }, null, -1),
                        _createTextVNode(" Sign In With Google ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("small", _hoisted_20, [
                  _cache[16] || (_cache[16] = _createTextVNode("Don’t have an account? ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'SignUp' },
                    class: "cm-color"
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode("Sign Up Now")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}